import React from 'react'
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";

export default function Paypalbuton({clientID, total, validate}) {
  
    const createOrder = (data, actions) => {
        return actions.order.create({
            purchase_units: [
                {
                    description: "Seminaire Connaitre L'allemagne",
                    amount: {
                        value: total,
                    },
                },
            ],
        });
    };

    const onApprove = async (data, actions) => {
        await actions.order.capture();
        validate();
    };



  return (
    <div>
        <p>Payment:</p>
        <PayPalScriptProvider options={{ clientId: clientID || "test", currency:"EUR" }}>
            <PayPalButtons
            createOrder={createOrder}
            onApprove={onApprove} 
            />
        </PayPalScriptProvider>
    </div>
  )
}
