import React, { Component, useEffect, useState, useRef } from "react";
import { SiteClient } from 'datocms-client';
import Section from "./section";
import About from "./About";
import Price from "./Price";
import Faq from "./Faq";
import Schedule from "./Schedule";
import { Toaster } from 'react-hot-toast';

const client = new SiteClient('84dee8dc4d0d03d1bdc0112665123a');

function Index()  {

    const [post, setPost] = useState([]);
    const [faqs, setFaqs] = useState([]);
    const [content, setContent] = useState([]);
    const [clientID, setClientID] = useState([]);
    const ref = useRef(null);

    const handleClick = () => {
        ref.current?.scrollIntoView({behavior: 'smooth'});
    };

    useEffect(() => {
        client.items.all({ filter: { type: 'acceuil' } })
            .then((post) => {
                setPost(post);
            })
            .catch((error) => {
                console.error(error);
            });

        client.items.all({ filter: { type: 'questionreponse' } })
            .then((faqs) => {
                setFaqs(faqs);
            })
            .catch((error) => {
                console.error(error);
            });

        client.items.all({ filter: { type: 'contenu_webinaire' } })
            .then((content) => {
                setContent(content);
            })
            .catch((error) => {
                console.error(error);
            });

        client.items.all({ filter: { type: 'paypal' } })
            .then((clientID) => {
                setClientID(clientID);
            });
    }, []);

    return (
        <React.Fragment>
            {/* Hero Start */}
            <Section post={post} handleClick={handleClick} />

            {/* About */}
            <About post={post} />

            <Schedule content={content} />

            {/* FAQ */}
            <Faq faqs={faqs} />

            {/* Price */}
            <Price  post={post} clientID={clientID.length > 0 ? clientID[0].clPublique: "AWBxKEjMkhDrWt2oYSsR-L-a27VtrhaAslG_M8lncH46zZPEB_wbP0nc3TXqPH1QwqRef4ZqicRM0hZ1" } />
            <div ref={ref}></div>

            <Toaster />

        </React.Fragment>
    );
}

export default Index;
