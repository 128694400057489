import React, {useState} from 'react';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Label,
  Button,
  Form,
} from 'reactstrap';
import FeatherIcon from 'feather-icons-react';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import Paypalbuton from './Paypalbuton';
import { useFormspark } from "@formspark/use-formspark";
import toast from 'react-hot-toast';

export default function Registration({price, clientID}) {
  const { register, handleSubmit, formState, getValues  } = useForm();
  const [showPaypal, setShowPaypal] = useState(false);
  const [submit, submitting] = useFormspark({
    formId: "yaTh125O",
  });

  const onSubmit = async (data) => {
    submit({...data, paid:"false"});
    setShowPaypal(true);
  };

  const validate = () => {
    submit({...getValues() , paid:"true"});
    setShowPaypal(false);
    toast.success('Votre Enregistrement a été effectué avec succès', {duration: 8000});
  }

  return ( 
    !showPaypal ?   <form className="mt-3" onSubmit={handleSubmit(onSubmit)}>
      <Row>
        <Col md={12}>
          <div className="mb-3">
            <Label className="form-label">
              Nom
              <span className="text-danger">*</span>
            </Label>
            <div className="form-icon position-relative">
              <FeatherIcon icon="user" className="fea icon-sm icons" />
              <input
                name="name"
                id="name"
                type="text"
                className="form-control ps-5"
                placeholder="Entrez votre nom"
                {...register('name', { required: true })} 
              />
            </div>
          </div>
        </Col>
        <Col md={12}>
          <div className="mb-3">
            <Label className="form-label">
              Email
              <span className="text-danger">*</span>
            </Label>
            <div className="form-icon position-relative">
              <FeatherIcon icon="mail" className="fea icon-sm icons" />
              <input
                name="email"
                id="email"
                type="email"
                className="form-control ps-5"
                placeholder="Entrez votre Email"
                {...register('email', { required: true })}
              />
            </div>
          </div>
        </Col>
        <Col md={12}>
          <div className="mb-3">
            <Label className="form-label">
              Pays
              <span className="text-danger">*</span>
            </Label>
            <div className="form-icon position-relative">
              <FeatherIcon icon="country" className="fea icon-sm icons" />
              <input
                name="country"
                id="country"
                type="text"
                className="form-control ps-5"
                placeholder="Entrez votre Pays"
                {...register('country', { required: true })}
              />
            </div>
          </div>
        </Col>
        <Col md={12}>
          <div className="mb-3">
            <Label className="form-label">
              Téléphone
              <span className="text-danger">*</span>
            </Label>
            <div className="form-icon position-relative">
              <FeatherIcon icon="phone" className="fea icon-sm icons" />
              <input
                name="phone"
                id="phone"
                type="number"
                className="form-control ps-5"
                placeholder="Entrez votre numéro"
                {...register('phone', { required: true })}
              />
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col sm={12}>
          <button
            type="submit"
            id="submit"
            name="send"
            className="btn"
            color="primary"
          > Send </button>
        </Col>
      </Row>
    </form>:
    <Paypalbuton clientID={clientID} total={price} validate={validate} />
  );
}
