import React, { useState } from "react";
import {Container, Row, Col, Card, CardBody, Label, Input, Button, Form} from "reactstrap";
import { Link } from "react-router-dom";

import ticket2 from "../../assets/images/icon/ticket2.svg";
import Registration from "./Registration";
import FeatherIcon from "feather-icons-react";

const Price = ({post, clientID}) => {
  const [showForm, setShowForm] = useState(false);

  const uniquePost = post[0];
  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     pricings: [
  //       {
  //         id: 2,
  //         title: "Paiement une fois",
  //         image: ticket2,
  //         price: "24",
  //         duration: "Full",
  //         desc:
  //           "Payer avec PayPal",
  //       }
  //     ],
  //     showForm: false
  //   };
  //   this.toggleForm = this.toggleForm.bind(this)
  // }

  const toggleForm = () => {
    setShowForm(!showForm)
  }

    return (
      <React.Fragment>
        <section className="section" id="ticketspay">
          <Container>
            {/* section title */}
            {/* <SectionTitle
              title="Our Tickets Rates"
              desc=" that can provide everything you need to generate awareness, drive traffic, connect."
            /> */}

            <Row className="justify-content-center align-items-center">
                <Col md="4" xs="12" className="mt-4 pt-2">
                  <Card
                    className="card pricing pricing-primary best-plan bg-light text-center border-0 rounded"
                  >
                    <CardBody className="py-5">
                      <img
                        src={ticket2}
                        className="mb-4"
                        height="50"
                        alt=""
                      />
                      <h6 className="title text-uppercase fw-bold mb-4">
                        Paiement unique
                      </h6>
                      <div className="d-flex justify-content-center mb-4">
                        <span className="price h1 mb-0">{uniquePost?.prisVNement}</span>
                        <span className="h4 align-self-center mb-1">
                         € 
                        </span>
                      </div>

                      <p className="text-muted"> <b>Payer avec:</b>  </p>
                      <ul className="feature list-unstyled pl-0">
                        <li className="h6 text-muted mb-0">Paypal</li>
                        <li className="h6 text-muted mb-0">Virement Bancaire</li>
                        <li className="h6 text-muted mb-0">Visa/Mastercard</li>
                      </ul>
                      <button
                        className="btn btn-primary btn-sm mt-4"
                        onClick={toggleForm}
                      >
                        Payer
                      </button>
                    </CardBody>
                  </Card>
                  {showForm &&
                    <Registration price={uniquePost?.prisVNement} clientID={clientID} />
                  }
                </Col>
            </Row>
          </Container>
        </section>

        <div className="position-relative">
          <div className="shape overflow-hidden text-light">
            <svg
              viewBox="0 0 2880 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
                fill="currentColor"
              ></path>
            </svg>
          </div>
        </div>
      </React.Fragment>
    );
  }

export default Price;
